import { Fragment, React, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Link } from "react-router-dom";
import { Bars3Icon, BellIcon, XMarkIcon, ArrowRightOnRectangleIcon, PencilIcon } from '@heroicons/react/24/outline'
import logo from '../../components/pernambucanas-logo.webp';
import axios from "axios";
import { UpdateLoja, UpdateUser } from "../modals/modalUpdate";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Content(props) {
    const nivel = sessionStorage.getItem('nivel');
    const user = sessionStorage.getItem('nome');
    const number = sessionStorage.getItem('msisdn');
    const loja = sessionStorage.getItem('loja')

    const cpf = sessionStorage.getItem('cpf')
    const [modal, setModal] = useState(false)
    const [modalLoja, setModalLoja] = useState(false)
    const [valuerecharge, setValuerecharge] = useState(0)
    const [statusChip, setStatusChip] = useState(0);
    const removeSession = () => {
        sessionStorage.removeItem('token');
    }
    const [selectedNav, setSelectedNav] = useState(-1);

    const handleNavClick = (index) => {
        setSelectedNav(index);
    };

    const navigation = [
        { name: 'Painel geral', link: '/dashboard' },
        { name: 'Historico', link: '/historico' },
        { name: 'CRM', link: '/consulta' },
        { name: 'Portabilidade', link: '/portabilidade' },
        { name: 'Cadastro', link: '/cadastro' },
        { name: 'Administrativo', link: '/adm' },
    ];
    const { children } = props;

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await axios.get(`https://backend.penambucanas.surf.com.br/dashboard/${cpf}`);
                setStatusChip(response.data.resultToSend[0]);
            } catch (error) {
                console.error('Failed to fetch dashboard data:', error);
            }
        };
        fetchDashboardData();
    }, [cpf]);

    return (
        <>
            <div className="min-h-screen">
                <Disclosure as="nav" className="bg-[#1d3c85]">
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-8 w-22"
                                                src={logo}
                                                alt="Pernambucanas"
                                            />
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item, index) => {
                                                    if (nivel !== '1' && nivel !== '0' && item.name === 'Administrativo') {
                                                        return null;
                                                    }

                                                    const isSelected = selectedNav === index;

                                                    return (
                                                        <Link
                                                            key={item.name}
                                                            to={item.link}
                                                            onClick={() => handleNavClick(index)}
                                                            className={`text-gray-300 rounded-md px-3 py-2 text-sm font-medium ${isSelected ? 'bg-gray-900 text-white' : 'hover:bg-gray-700 hover:text-white'
                                                                }`}
                                                            aria-current={isSelected ? 'page' : undefined}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">

                                            {/* Profile dropdown */}
                                            <Menu as="div" className="relative ml-3">
                                                <button
                                                    type="button"
                                                    className="rounded-full bg-[#fddd04] p-2 text-white shadow-sm hover:bg-[#f0d121] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                    onClick={() => {
                                                        removeSession();
                                                        window.location.href = '/';
                                                    }}
                                                >
                                                    <ArrowRightOnRectangleIcon className="h-5 w-5" aria-hidden="true" />
                                                </button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="mt-3 space-y-1 px-2">
                                                            <h1 className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                                                onClick={() => {
                                                                    removeSession();
                                                                    window.location.href = '/';
                                                                }
                                                                }
                                                            >Sair</h1>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">

                                        {/* Mobile menu button */}
                                        <Disclosure.Button
                                            className="inline-flex items-center justify-center rounded-md bg-[#fddd04] p-2 text-gray-900 hover:bg-[#fddd04] hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>
                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            className={classNames(
                                                item.current ? 'bg-[#fddd04] text-[#1d3c85]' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'block rounded-md px-3 py-2 text-base font-medium'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            <Link to={item.link} className="w-full h-full">
                                                {nivel === '1' || nivel === '0' ? (
                                                    <>
                                                        {item.name}
                                                    </>
                                                ) : (
                                                    <>
                                                        {item.name === 'Administrativo' ? (
                                                            <>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {item.name}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Link>
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                <div className="border-t border-gray-700 pt-4 pb-3">
                                    <div className="flex items-center px-5">
                                        <div className="flex flex-col">
                                            <div className="text-base font-medium text-white">{user}</div>
                                            <div className="text-sm font-medium text-gray-400">{number}</div>
                                        </div>
                                    </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        <h1 className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                            onClick={() => {
                                                removeSession();
                                                window.location.link = '/';
                                            }
                                            }
                                        >Sair</h1>
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <header className="bg-gray-200 shadow-sm">
                    <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-row">
                            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Olá, {user}</h1>

                        </div>
                        <div className="flex flex-row">
                            <h1 className="text-1xl font-bold mt-1">Seu numero: </h1>
                            <h1 className="text-1xl font-medium mt-1 ml-2">{
                                number === null ? (
                                    <>
                                        Numero não cadastrado
                                    </>
                                ) : (
                                    <>
                                        {number}
                                    </>
                                )
                            }</h1>
                            <button
                                type="button"
                                className="rounded-full ml-2 mt-1 bg-[#1d3c85] p-2 text-white shadow-sm hover:bg-[#2550b6] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={() => setModal(!modal)}
                            >
                                <PencilIcon className="h-3 w-auto" aria-hidden="true" />
                            </button>
                            {
                                modal ? (
                                    <>
                                        <UpdateUser />
                                    </>
                                ) : null
                            }
                        </div>
                        <div className="flex flex-row">
                            <h1 className="text-sm font-bold mt-1">Sua loja: </h1>
                            <h1 className="text-sm font-medium mt-1 ml-2">{
                                loja === null ? (
                                    <>
                                        Loja não cadastrada
                                    </>
                                ) : (
                                    <>
                                        {loja}
                                    </>
                                )
                            }</h1>
                            <button
                                type="button"
                                className="rounded-full ml-2 bg-[#1d3c85] p-2 text-white shadow-sm hover:bg-[#2550b6] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={() => setModalLoja(!modalLoja == true)}
                            >
                                <PencilIcon className="h-3 w-auto" aria-hidden="true" />
                            </button>
                            {
                                modalLoja ? (
                                    <>
                                        <UpdateLoja />
                                    </>
                                ) : null
                            }
                        </div>
                    </div>
                </header>
                <main>
                    <div className="mx-auto max-w-7xl py-1 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            {children}
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}