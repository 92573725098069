import { useState } from 'react'
import logo from '../components/pernambucanas-logo.webp'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ModalCreateAccount from '../components/modals/modalCreate'
import ReCAPTCHA from "react-google-recaptcha";

export default function CadastroUser() {
    const [name, setName] = useState('')
    const [cpf, setCpf] = useState('')
    const [msisdn, setmsisdn] = useState('')
    const [loja, setLoja] = useState('')
    const [matricula, setMatricula] = useState('')
    const [error, setError] = useState(false)

    const handleNewuser = async (e) => {
        e.preventDefault()
        const data = {
            msisdn_vendedor: msisdn,
            nome_colaborador: name,
            cpf_vendedor: cpf,
            loja_vendedor: loja,
            matricula_vendedor: matricula,
        }
        try {
            const response = await axios.post('https://backend.penambucanas.surf.com.br/newuser', data)
            if (response.status === 200) {
                setError(true)
            } else {
                setError(false)
            }
        } catch (error) {
            if (error.response && error.response.data.message === 'CPF já cadastrado no sistema.') {
                alert('CPF já cadastrado!');
            } else if (error.response && error.response.data.message === 'Matricula já cadastrada no sistema.') {
                alert('Matricula já cadastrada!');
            }
        }
    }
    const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setIsCaptchaValidated(true);
    }
    return (
        <>
            <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-[#fddd04] dark:bg-slate-400">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={logo}
                        alt="Pernambucanas"
                    />
                    {
                        error !== false ? <ModalCreateAccount /> : true
                    }
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Crie sua conta</h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        ou {' '}
                        <a className="font-medium text-blue-600 hover:text-blue-500">
                            <Link to="/">já possui acesso ?</Link>
                        </a>
                    </p>
                </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md ">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={handleNewuser}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nome
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        autoComplete="name"
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    CPF
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="cpf"
                                        name="cpf"
                                        type="number"
                                        autoComplete="current-password"
                                        required
                                        onChange={(e) => setCpf(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Telefone
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="phone"
                                        name="password"
                                        type="tel"
                                        autoComplete="current-password"
                                        required
                                        onChange={(e) => setmsisdn(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Matrícula
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="matricula"
                                        name="matricula"
                                        type="number"
                                        autoComplete="current-password"
                                        required
                                        onChange={(e) => setMatricula(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Loja
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="loja"
                                        name="loja"
                                        type="number"
                                        autoComplete="current-password"
                                        required
                                        onChange={(e) => setLoja(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"

                                >
                                    Criar conta
                                </button>
                            </div>
                            <div className='flex w-full justify-center'>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SECRET_ROBOT}
                                    onChange={handleCaptchaChange}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
