import Content from "../components/content/content";
import { React, useState } from "react";
import { Switch } from '@headlessui/react'
import { QrCodeIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import ModalScanne from '../components/modals/modalScann';
import { SmCadastro } from '../components/alert/smAlert';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Cadastro() {
  const nome = sessionStorage.getItem('nome');
  const msisdn = sessionStorage.getItem('msisdn');
  const loja = sessionStorage.getItem('loja')
  const comsisdn = sessionStorage.getItem('comsisdn')
  const matricula = sessionStorage.getItem('matricula')
  const cpf = sessionStorage.getItem('cpf')
  const id = sessionStorage.getItem('id')
  const [iccid, setIccid] = useState('');
  const [plano, setPlano] = useState('');
  const [termPortabilidade, setTermPortabilidade] = useState(false);
  const [data, setData] = useState('');
  const [sucess, setSucess] = useState(false);
  const [result, setResult] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dataInvalida, setDataInvalida] = useState(false);
  const onDetected = result => {
    setResult(result);
  };

  function calculateStartOfWeek() {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

    const startOfWeek = new Date(now.setDate(diff));
    return startOfWeek.toISOString().split('T')[0];
  }

  const handleDataChange = (e) => {
    const { value } = e.target;
    setData(value);
    setDataInvalida(isDateOutOfRange(value));
  };

  function isDateOutOfRange(dateValue) {
    const date = new Date(dateValue);
    const startOfWeek = new Date(calculateStartOfWeek());
    const endOfWeek = new Date(calculateEndOfWeek());

    return date < startOfWeek || date > endOfWeek;
  }

  function calculateEndOfWeek() {
    const now = new Date();
    const dayOfWeek = now.getUTCDay();
    const diff = now.getUTCDate() + (8 - dayOfWeek);
    const endOfWeek = new Date(now.setUTCDate(diff));
    endOfWeek.setUTCHours(-3);
    return endOfWeek.toISOString().split('T')[0];
  }

  const handleCadastro = async (e) => {
    e.preventDefault();
    const request = {
      idusuario: id,
      iccid_venda: iccid,
      data_venda: data,
      msisdn_vendedor: msisdn,
      matricula_vendedor: matricula,
      comsisdn_vendedor: comsisdn,
      cpf_vendedor: cpf,
      loja_vendedor: loja,
      plano: plano,
      portabilidade: termPortabilidade,
    }
    setIsLoading(true);
    try {
      const response = await axios.post('https://backend.penambucanas.surf.com.br/cadastro', request)
      if (response.status === 200) {
        setSucess(true)
        setError(false)
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (err) {
      if (err.response.status === 400) {
        setIsLoading(false);
      } else if (err.response && err.response.data.message === 'ICCID já cadastrado no sistema.') {
        alert('Matricula já cadastrada!');
      }
    }
  }
  return (
    <>
      <Content title={
        <>
          <h1 className="text-2xl font-semibold leading-6 text-gray-900">Olá, {nome}</h1>
          <div className="flex flex-row">
            <h1 className="text-1xl font-bold mt-1">Seu numero: </h1>
            <h1 className="text-1xl font-medium mt-1 ml-2">{msisdn}</h1>
          </div>
          <div className="flex flex-row">
            <h1 className="text-sm font-bold mt-1">Sua loja: </h1>
            <h1 className="text-sm font-medium mt-1 ml-2">{loja}</h1>
          </div>
        </>
      }
        children={
          <>
            <div className='flex flex-col'>
              <h1 className='text-2xl font-semibold '>
                Informe os dados da compra
              </h1>
            </div>
            <div className="mt-4">
              {
                sucess !== false ? <SmCadastro /> : null
              }
              {
                error !== false ? <SmCadastro /> : null
              }
            </div>
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mt-2">
              <form onSubmit={handleCadastro}>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className={`col-span-2`}>
                      <label htmlFor="first_name"
                        className="block text-sm font-medium text-gray-700">
                        ICCID
                      </label>
                      <div className='flex flex-row'>
                        <input
                          type="text"
                          name="iccid"
                          id="iccid"
                          minLength={19}
                          maxLength={19}
                          value={result ? result || iccid : iccid}
                          autoComplete="given-name"
                          required
                          onChange={(e) => setIccid(e.target.value)}
                          className="mt-1 focus:ring-[#244dae] focus:border-[#244dae] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <button
                          onClick={() => setModal(true)}
                        >

                          <QrCodeIcon className='ml-2 h-10' />
                        </button>
                        {
                          modal ? (
                            <>
                              <ModalScanne />
                            </>
                          ) : null
                        }
                      </div>
                    </div>
                    <div className={`col-span-2`}>
                      <label htmlFor="plan"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Plano
                      </label>
                      <select
                        id="lplan"
                        name="plan"
                        onChange={(e) => setPlano(e.target.value)}
                        required
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-[#244dae] sm:text-sm sm:leading-6"
                        defaultValue="SELECIONE"
                      >
                        <option></option>
                        <option>Pré-Pago</option>
                        <option>Plano Controle Inteligente</option>
                      </select>
                    </div>
                    <div className={`col-span-1 flex items-center`}>
                      <Switch.Group as="div" className="flex items-center">
                        <Switch
                          checked={termPortabilidade}
                          onChange={setTermPortabilidade}
                          className={classNames(
                            termPortabilidade ? 'bg-blue-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              termPortabilidade ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                            onChange={(e) => setTermPortabilidade(e.target.value)}
                          />
                        </Switch>
                        <Switch.Label as="span" className="ml-3 text-sm">
                          <span
                            className="font-medium text-gray-900">Portabilidade</span>{' '}
                        </Switch.Label>
                      </Switch.Group>
                    </div>
                    <div className={`col-span-2`}>
                      <label htmlFor="first_name" className="mt-4 block text-sm font-medium text-gray-700">
                        Data da Venda
                      </label>
                      <div className='flex flex-row'>
                        <input
                          type="date"
                          name="vendas"
                          id="vendas"
                          required
                          onChange={handleDataChange}
                          className={`mt-1 focus:ring-[#1d3c85] focus:border-[#1d3c85] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${dataInvalida ? 'border-red-500' : ''}`}
                          min={calculateStartOfWeek()}
                          max={calculateEndOfWeek()}
                        />
                      </div>
                      {dataInvalida && <p className="text-red-500 text-sm mt-1">A data não é válida.</p>}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={`flex w-full mt-4 justify-center rounded-md bg-[#1d3c85] py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-[#244dae] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#244dae] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={isLoading || dataInvalida}
                  >
                    {isLoading ? (
                      <div role="status">
                        <svg aria-hidden="true"
                          className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                          viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor" />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill" />
                        </svg>
                      </div>
                    ) : (
                      'Confirmar'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </>
        }
      />
    </>
  )

}