import { Fragment, useLayoutEffect, useRef, useState, useEffect, React } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { CSVDownload, CSVLink } from 'react-csv';
import { XCircleIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import axios from 'axios';

function RenderRelatorio() {
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(true);
  const [people, setPeople] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    axios
      .get('https://backend.penambucanas.surf.com.br/relatorio', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setPeople(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const csvData = filteredData.map((person) => ({
    'Loja Vendedor': person.loja_vendedor,
    'Nome Vendedor': person.nome_colaborador,
    'CPF Vendedor': person.cpf_vendedor,
    'Numero Vendedor': person.msisdn_vendedor,
    'Nome da Loja': person.nome_loja,
    'Loja Cidade': person.loja_cidade,
    'Loja UF': person.loja_uf,
    'ICCID Venda': person.iccid_venda,
    'Plano': person.plano === null ? 'Não se aplica' : person.plano,
    'Portabilidade': person.portabilidade === null || person.portabilidade === 'false' ? 'Não se aplica' : person.portabilidade ? 'Sim' : 'Não',
    'Status Portabilidade': person.status_portabilidade === null || person.status_portabilidade === 'false' || person.status_portabilidade === 'undefined' ? 'Sem status' : person.status_portabilidade ? 'Sim' : 'Não',
    'Status Chip': person.status_chip_cliente === '' ? 'Não concluída' : person.status_chip_cliente === null ? 'Não concluída' : person.status_chip_cliente,
    'Data Venda': person.data_venda,
    'Data Cadastro': person.data_cadastro === null || person.data_cadastro === 'undefined' || person.data_cadastro === false ? 'Sem data' : person.data_cadastro,
  }));


  function changePage(pageNumber) {
    setCurrentPage(pageNumber);
  }

  let dataToShow = filter === '' ? people : filteredData;

  const totalPages = Math.ceil(dataToShow.length / resultsPerPage);
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = dataToShow.slice(indexOfFirstResult, indexOfLastResult);
  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return; // Não faz nada se a página estiver fora do intervalo válido
    }
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const dataStart = new Date(dataInicial).toLocaleDateString('pt-BR');
    const dataEnd = new Date(dataFinal).toLocaleDateString('pt-BR');
    const data = {
      data_inicial: dataStart,
      data_final: dataEnd,
    };

    const search = () => {
      const filteredData = people.filter((val) => {
        const { cpf_vendedor, iccid_venda, loja_vendedor, data_venda } = val;
        const lowerCaseFilter = filter.toLowerCase();

        if (!filter) {
          return true;
        }

        const matchesFilter = (
          cpf_vendedor && cpf_vendedor.toLowerCase().includes(lowerCaseFilter) ||
          iccid_venda && iccid_venda.toLowerCase().includes(lowerCaseFilter) ||
          loja_vendedor && loja_vendedor.toLowerCase().includes(lowerCaseFilter)
        );

        if (matchesFilter) {
          return true;
        }

        // Verifique se a data da venda está dentro do intervalo especificado
        const vendaDate = new Date(data_venda).toLocaleDateString('pt-BR');
        if (vendaDate >= dataStart && vendaDate <= dataEnd) {
          return true;
        }

        return false; // Nenhum filtro correspondeu
      });


      setFilteredData(filteredData);
    };

    search();
  }, [filter, people, dataInicial, dataFinal]);



  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg w-full bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                <>
                  <div className="px-4 sm:px-6 lg:px-8 w-full">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900">ee</h1>
                      </div>
                      <div className={`flex items-center justify-center mt-12 sm:mt-0 sm:ml-4 sm:mb-4`}>
                        <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                          <button
                            type="button"
                            className="block rounded-md bg-[#1d3c85] py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#1d48ad] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1d3c85]"
                          >
                            <CSVLink data={csvData} filename={"relatorio.csv"}>
                              Exportar Relatório
                            </CSVLink>

                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-3 gap-4 sm:grid-cols-2 sm:gap-2 sm:mt-5">
                      <div className="col-span-1 mr-4 sm:mr-0">
                        <label htmlFor="account-number"
                          className="block text-sm font-medium leading-6 text-gray-900">
                          Loja Vendedor
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="account-number"
                            id="account-number"
                            onChange={(e) => setFilter(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="123456789010"
                          />
                        </div>

                      </div>
                      <div className={'col-span-1'}>
                        <label htmlFor="account-number"
                          className="block text-sm font-medium leading-6 text-gray-900">
                          CPF
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="account-number"
                            id="account-number"
                            onChange={(e) => setFilter(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="000-000-000.00"

                          />

                        </div>

                      </div>
                      <div className={'col-span-1 ml-4 sm:ml-0'}>
                        <label htmlFor="account-number"
                          className="block text-sm font-medium leading-6 text-gray-900">
                          ICCID
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="account-number"
                            id="account-number"
                            onChange={(e) => setFilter(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="123456789010"
                          />
                        </div>
                      </div>

                      <div className={'col-span-1 ml-4 sm:ml-0'}>
                        <label htmlFor="account-number" className="block text-sm font-medium leading-6 text-gray-900">
                          Data inicial
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <input
                            type="datetime-local"
                            id="meeting-time"
                            onChange={(e) => setFilter(e.target.value)}
                            onChangeCapture={(e) => setDataInicial(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className={'col-span-1 ml-4 sm:ml-0'}>
                        <label htmlFor="account-number" className="block text-sm font-medium leading-6 text-gray-900">
                          Data Fim
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <input
                            type="datetime-local"
                            id="meeting-time"
                            onChange={(e) => setFilter(e.target.value)}
                            onChangeCapture={(e) => setDataFinal(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                  Loja Vendedor
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Nome Vendedor
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  CPF Vendedor
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Msisdn Vendedor
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Loja UF
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  ICCID Venda
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Plano
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Portabilidade
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Status Portabilidade
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Status Chip
                                </th>
                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Data Venda
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {currentResults.map((person) => (
                                <tr key={person.id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {person.loja_vendedor}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.nome_colaborador}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.cpf_vendedor}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.msisdn_vendedor}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.loja_cidade}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.loja_uf}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.iccid_venda}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.plano}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.portabilidade === null ||
                                      person.portabilidade === '' ||
                                      person.portabilidade === 'false'
                                      ? 'Não '
                                      : person.portabilidade === 'true'
                                        ? 'Sim'
                                        : 'Não'}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.status_chip_cliente === ''
                                      ? 'Não concluída'
                                      : person.status_chip_cliente === 'false'
                                        ? 'Sem Portabilidade'
                                        : person.portabilidade === 'true'
                                          ? 'Sim'
                                          : 'Não se aplica'
                                    }
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.status_chip_cliente === ''
                                      ? 'Não concluída'
                                      : person.status_chip_cliente === null
                                        ? 'Não concluída'
                                        : person.status_chip_cliente
                                    }
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {person.data_venda}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Mostrando{' '}
                        <span className="font-medium">{indexOfFirstResult + 1}</span> até{' '}
                        <span className="font-medium">
                          {indexOfLastResult > people.length ? people.length : indexOfLastResult}
                        </span>{' '}
                        de <span className="font-medium">{people.length}</span> Resultados
                      </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                      <button
                        className={`relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold ${currentPage === 1 ? 'text-gray-300' : 'text-gray-900'
                          } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0`}
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Anterior
                      </button>
                      <button
                        className={`relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold ${indexOfLastResult >= people.length ? 'text-gray-300' : 'text-gray-900'
                          } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0`}
                        onClick={() => paginate(currentPage + 1)}
                        disabled={indexOfLastResult >= people.length}
                      >
                        Próximo
                      </button>
                    </div>
                  </nav>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>)
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RenderAlter() {
  const [open, setOpen] = useState(true)
  const checkbox = useRef()
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState([])
  const [people, setPeople] = useState([])
  const [aprove, setAprove] = useState(false);
  const [recuse, setRecuse] = useState(false);
  const loja = sessionStorage.getItem('loja');
  const id = sessionStorage.getItem('id')
  const [sucess, setSucess] = useState(false)

  useLayoutEffect(() => {
    const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(selectedPeople.length === people.length);
    setIndeterminate(isIndeterminate);
  }, [selectedPeople]);


  useEffect(() => {
    const token = sessionStorage.getItem('token')
    axios.get('https://backend.penambucanas.surf.com.br/alteraget', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setPeople(response.data)
      console.log(response.data)
    }).catch((error) => {
      console.log(error)
    });
  }, []);
  const handleAprove = () => {
    const token = sessionStorage.getItem('token');
    const cpf_vendedor = selectedPeople.map((person) => person.cpf_vendedor);
    const newloja = selectedPeople.map((person) => person.loja_vendedor);
    const loja_old = selectedPeople.map((person) => person.old_loja);
    const matricula = selectedPeople.map((person) => person.matricula);
    const idbase = selectedPeople.map((person) => person.id_usuario);
    const data = {
      loja: loja_old[0], newloja: newloja[0], cpf_vendedor: cpf_vendedor[0], matricula: matricula[0],
    };
    console.log(data)
    axios
      .post('https://backend.penambucanas.surf.com.br/alterarLoj', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data) {
          setAprove(true);
          setTimeout(() => {
            setAprove(true);
            window.location.reload();
          }, 3000);

          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      });

  };
  const handleRecuse = () => {
    const token = sessionStorage.getItem('token');
    const id = selectedPeople.map((person) => person.id_usuario);
    const loja = selectedPeople.map((person) => person.loja_vendedor);
    const data = {
      id: id, newloja: loja,
    };
    axios
      .post('https://backend.penambucanas.surf.com.br/recuseloja', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data) {
          setRecuse(true);
          setTimeout(() => {
            setRecuse(true);
            window.location.reload();
          }, 3000);
          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      });

  };


  return (<Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={setOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>
      {sucess !== false ? (<div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Loja atualizado com sucesso</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>O novo numero de loja estara disponivel após aprovação de um
                administrador.</p>
            </div>
          </div>
        </div>
      </div>) : null}
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
              <div className="px-4 sm:px-6 lg:px-8">

                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Alteração de
                      Loja</h1>
                    <p className="mt-2 text-sm text-gray-700">
                      Esse portal é destinado para a aprovação de alterações de loja.
                    </p>
                    <div className='mt-3 mb-2'>
                      {aprove !== false ? (<div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-green-400"
                              aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-green-800">Sucesso!
                              Usuário alteado com sucesso </h3>
                          </div>
                        </div>
                      </div>) : null}
                      {recuse !== false ? (<div className="rounded-md bg-red-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-400"
                              aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Sucesso!
                              Usuário recusado com sucesso </h3>
                          </div>
                        </div>
                      </div>) : null}
                    </div>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="relative">
                        <table className="min-w-full table-fixed divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th scope="col"
                                className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">

                              </th>
                              <th scope="col"
                                className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                Name Colaborador
                              </th>
                              <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                CPF
                              </th>
                              <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Loja Nova
                              </th>
                              <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Loja Antiga
                              </th>
                              <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Matricula
                              </th>
                              <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Msisdn
                              </th>
                              <th scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {people.map((person) => (
                              <tr key={person.id}
                                className={selectedPeople.includes(person) ? 'bg-gray-50' : undefined}>
                                <td className="relative px-7 sm:w-12 sm:px-6">
                                  {selectedPeople.includes(person) && (<div
                                    className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />)}
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                    value={person.email}
                                    checked={selectedPeople.includes(person)}
                                    onChange={(e) => setSelectedPeople(e.target.checked ? [...selectedPeople, person] : selectedPeople.filter((p) => p !== person))}
                                  />
                                </td>
                                <td
                                  className={classNames('whitespace-nowrap py-4 pr-3 text-sm font-medium', selectedPeople.includes(person) ? 'text-blue-600' : 'text-gray-900')}
                                >
                                  {person.nome_colaborador}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.cpf_vendedor}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.loja_vendedor}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.old_loja}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.matricula}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.msisdn}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                                  <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-green-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-700 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    onChange={(e) => {
                                      setAprove(e.target.value)
                                    }}
                                    defaultValue={aprove}
                                    onClick={handleAprove}
                                  >
                                    Aprovar<span className="sr-only">, </span>

                                  </button>
                                  <button
                                    type="button"
                                    className="inline-flex ml-3 items-center rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-700 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    defaultValue={recuse}
                                    onClick={handleRecuse}
                                  >
                                    Recusar<span className="sr-only">, </span>
                                  </button>
                                </td>
                              </tr>))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>

  )
}

function RenderLoja() {

  const [open, setOpen] = useState(true)
  const checkbox = useRef()
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState([])
  const [people, setPeople] = useState([])
  const [aprove, setAprove] = useState(false)
  const [recuse, setRecuse] = useState(false)
  const [sucess, setSucess] = useState(false)

  useLayoutEffect(() => {
    const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(selectedPeople.length === people.length);
    setIndeterminate(isIndeterminate);
  }, [selectedPeople]);

  useEffect(() => {
    const token = sessionStorage.getItem('token')
    axios.get('https://backend.penambucanas.surf.com.br/usersaprove', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setPeople(response.data)
      console.log(response.data)
    }).catch((error) => {
      console.log(error)
    });
  }, []);
  const handleRecuse = () => {
    const token = sessionStorage.getItem('token');
    const cpf = selectedPeople.map((person) => person.cpf_vendedor);
    const data = {
      cpf: cpf, status: 'INACTIVE',
    };
    axios
      .post('https://backend.penambucanas.surf.com.br/recuseUser', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.message === 'Usuário recusado com sucesso') {
          setRecuse(true);
          setTimeout(() => {
            setRecuse(false);
            window.location.reload();
          }, 3000);

          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleAprove = () => {
    const token = sessionStorage.getItem('token');
    const cpf = selectedPeople.map((person) => person.cpf_vendedor);

    const data = {
      cpf: cpf, status: 'ACTIVE',
    };

    const response = axios
      .post('https://backend.penambucanas.surf.com.br/aproveUser', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.message === 'Usuário aprovado com sucesso') {
          setAprove(true);
          setTimeout(() => {
            setRecuse(false);
            window.location.reload();
          }, 3000);
          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    if (response.data.message === 'Usuário aprovado com sucesso') {
      setSucess(true)
      setTimeout(() => {
        setSucess(false)
      }, 3000)
    }
  };

  return (

    <Transition.Root show={open} as={Fragment}>

      <Dialog as="div" className="relative z-10" onClose={setOpen}>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >

          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-gray-900">Alteração de
                        acesso</h1>
                      <p className="mt-2 text-sm text-gray-700">
                        Esse portal é destinado para a aprovação de alterações de acesso dos
                        vendedores.
                      </p>
                    </div>
                  </div>
                  <div className='mt-3 mb-2'>
                    {aprove && (<div className="rounded-md bg-green-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400"
                            aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-green-800">Sucesso!
                            Usuário aprovado com sucesso </h3>
                        </div>
                      </div>
                    </div>)}
                    {recuse && (<div className="rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="h-5 w-5 text-red-400"
                            aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">Sucesso!
                            Usuário recusado com sucesso </h3>
                        </div>
                      </div>
                    </div>)}
                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="relative">
                          {selectedPeople.length > 0 && (<div
                            className="absolute top-0 left-14 flex h-12 items-center space-x-3 bg-white sm:left-12">

                          </div>)}
                          <table className="min-w-full table-fixed divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th scope="col"
                                  className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">

                                </th>
                                <th scope="col"
                                  className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                  Name Colaborador
                                </th>
                                <th scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  CPF
                                </th>
                                <th scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Loja
                                </th>
                                <th scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Matricula
                                </th>
                                <th scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Msisdn
                                </th>
                                <th scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {people.map((person) => (<tr key={person.email}
                                className={selectedPeople.includes(person) ? 'bg-gray-50' : undefined}>
                                <td className="relative px-7 sm:w-12 sm:px-6">
                                  {selectedPeople.includes(person) && (<div
                                    className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />)}
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                    value={person.email}
                                    checked={selectedPeople.includes(person)}
                                    onChange={(e) => setSelectedPeople(e.target.checked ? [...selectedPeople, person] : selectedPeople.filter((p) => p !== person))}
                                  />
                                </td>
                                <td
                                  className={classNames('whitespace-nowrap py-4 pr-3 text-sm font-medium', selectedPeople.includes(person) ? 'text-blue-600' : 'text-gray-900')}
                                >
                                  {person.nome_colaborador}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.cpf_vendedor}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.loja_vendedor}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.matricula_vendedor}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.msisdn_vendedor}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                                  <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-green-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-700 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    onChange={(e) => {
                                      setAprove(e.target.value)
                                    }}

                                    onClick={handleAprove}
                                  >
                                    Aprovar<span className="sr-only">, </span>

                                  </button>
                                  <button
                                    type="button"
                                    className="inline-flex ml-3 items-center rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-700 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    defaultValue={recuse}
                                    onClick={handleRecuse}
                                  >
                                    Recusar<span className="sr-only">, </span>
                                  </button>
                                </td>
                              </tr>))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>

    </Transition.Root>

  )
}

export { RenderRelatorio, RenderAlter, RenderLoja }