import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './views/login';
import Dashboard from './views/dashboard';
import Historic from './views/historico';
import Cadastro from './views/cadastro';
import Administrativo from './views/administrativo';
import Dashboard2 from './views/new-dashbard';
import CadastroUser from './views/newUser';
import { PrivateRoute } from './auth/AuthProvider';
import ConsultAccount from './views/consulta';
import PortabilidadeService from './views/portin';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/newaccount" element={<CadastroUser />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/historico" element={<PrivateRoute><Historic /></PrivateRoute>} />
        <Route path="/cadastro" element={<PrivateRoute><Cadastro /></PrivateRoute>} />
        <Route path="/newdashboard" element={<PrivateRoute><Dashboard2 /></PrivateRoute>} />
        <Route path="/adm" element={<PrivateRoute adminOnly={true}><Administrativo /></PrivateRoute>} />
        <Route path="/consulta" element={<ConsultAccount />} />
        <Route path="/portabilidade" element={<PortabilidadeService />} />
      </Routes>
    </Router>
  );
}

export default App;
