import { React, Fragment, useEffect, useState } from 'react';
import { Spinner, Progress } from "@nextui-org/react";
import Notifications from '../components/notifications/notifications.components';
import Content from "../components/content/content";
import api from '../api/config.axios';

export default function PortabilidadeService() {

    const notificationMethods = [
        { id: 'cpf', title: 'CPF' },
        { id: 'cnpj', title: 'CNPJ' },
    ]

    const [selectedMethod, setSelectedMethod] = useState('cpf');
    const [msisdn, setMsisdn] = useState('');
    const [cpf, setCpf] = useState('');
    const [nome, setNome] = useState('');
    const [telefoneOutraOperadora, setTelefoneOutraOperadora] = useState('');
    const [email, setEmail] = useState('');
    const [operadora, setOperadora] = useState('');
    const [show, setOpen] = useState(false);
    const [success, setSuccess] = useState({});
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);


    const handlePortabilidade = async (e) => {
        e.preventDefault();
        const data = {
            msisdn: msisdn,
            msisdnOutraOperadora: telefoneOutraOperadora,
            operadora: operadora,
            documento: cpf,
            nomeCompleto: nome,
            email: email,
        }
        try {
            const response = await api.post('/send-portin', data);
            if (response.status === 200) {
                setOpen(true);
                setSuccess(response.data);
                setTimeout(() => {
                    setOpen(false);
                }, 5000);
            }
        } catch (error) {
            if (e.response.status === 404) {
                setOpen(true);
                setError(e.response.data);
                setTimeout(() => {
                    setOpen(false);
                }, 5000);
            } else if (e.response.status === 401) {
                setOpen(true);
                setError(e.response.data);
                setTimeout(() => {
                    setOpen(false);
                    window.location.href = '/';
                }, 5000);
            } else if (e.response.status === 403) {
                setOpen(true);
                setError(e.response.data);
            }
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Fragment>
            <Content>
                <div className='sm:top-0 z-60'>
                    <Notifications
                        data={error}
                        show={show}
                        setShow={setOpen} />
                </div>
                {
                    isLoading && (
                        <Progress
                            color="primary"
                            size="sm"
                            isIndeterminate
                            className="fixed top-0 left-0 z-50"
                        />
                    )
                }
                <div className='mt-4'>
                    <form onSubmit={handlePortabilidade}>
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-2xl  font-semibold leading-7 text-gray-900">Portabilidade</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Efetue portabilidade de linhas Pernambucanas.</p>
                                <div>
                                    <fieldset className="mt-4">
                                        <legend className="sr-only">Notification method</legend>
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                            {notificationMethods.map((notificationMethod) => (
                                                <div key={notificationMethod.id} className="flex items-center">
                                                    <input
                                                        id={notificationMethod.id}
                                                        name="notification-method"
                                                        type="radio"
                                                        checked={selectedMethod === notificationMethod.id}
                                                        onChange={() => setSelectedMethod(notificationMethod.id)}
                                                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                                    />
                                                    <label htmlFor={notificationMethod.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                        {notificationMethod.title}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            {selectedMethod === 'cpf' ? 'CPF' : 'CNPJ'}
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="first-name"
                                                id="first-name"
                                                autoComplete="given-name"
                                                maxLength={selectedMethod === 'cpf' ? 11 : 14}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => { setCpf(e.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Nome Completo
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="last-name"
                                                id="last-name"
                                                autoComplete="family-name"
                                                maxLength={255}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => { setNome(e.target.value) }}

                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Telefone
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="text"
                                                autoComplete="email"
                                                maxLength={11}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => { setMsisdn(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Telefone Outra Operadora
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="text"
                                                autoComplete="email"
                                                maxLength={11}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => { setTelefoneOutraOperadora(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Email
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                maxLength={255}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                            OPERADORA
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="country"
                                                name="country"
                                                autoComplete="country-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                defaultValue="SELECIONE"
                                                onChange={(e) => { setOperadora(e.target.value) }}
                                            >
                                                <option></option>
                                                <option>TIM</option>
                                                <option>CLARO</option>
                                                <option>OI</option>
                                                <option>VIVO</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900">
                                            MARCA
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="text"
                                                name="text"
                                                type="text"
                                                autoComplete="text"
                                                placeholder='Pernambucanas'
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                value={'PERNAMBUCANAS'}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                                type="submit"
                                className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            >
                                {isLoading ? (
                                    <>
                                        <div className='flex justify-center items-center ml-8'>
                                            <Spinner
                                                size="sm"
                                                color="warning"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        Enviar
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </Content>
        </Fragment>
    )
}