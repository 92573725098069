import { Fragment, useLayoutEffect, useRef, useState, useEffect, React } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { CSVLink } from 'react-csv';
import axios from 'axios';

export default function RenderRelatorio2() {
    const [filteredData, setFilteredData] = useState([]);
    const [open, setOpen] = useState(true);
    const [people, setPeople] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [lojaVendedorFilter, setLojaVendedorFilter] = useState('');
    const [cpfFilter, setCpfFilter] = useState('');
    const [iccidFilter, setIccidFilter] = useState('');
    const [dataInicialFilter, setDataInicialFilter] = useState('');
    const [dataFinalFilter, setDataFinalFilter] = useState('');


    useEffect(() => {
        const token = sessionStorage.getItem('token');
        axios
            .get('https://backend.penambucanas.surf.com.br/relatorio', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (response.data.length > 0) {
                    setPeople(response.data);
                    setFilteredData(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    const csvData = filteredData.map((person) => ({
        'Loja Vendedor': person.loja_vendedor,
        'Nome Vendedor': person.nome_colaborador,
        'CPF Vendedor': person.cpf_vendedor,
        'Numero Vendedor': person.msisdn_vendedor,
        'Nome da Loja': person.nome_loja,
        'Loja Cidade': person.loja_cidade,
        'Loja UF': person.loja_uf,
        'ICCID Venda': person.iccid_venda,
        'Plano': person.plano === null ? 'Não se aplica' : person.plano,
        'Portabilidade': person.portabilidade === null || person.portabilidade === 'false' ? 'Não se aplica' : person.portabilidade ? 'Sim' : 'Não',
        'Status Portabilidade': person.status_portabilidade === null || person.status_portabilidade === 'false' || person.status_portabilidade === 'undefined' ? 'Sem status' : person.status_portabilidade ? 'Sim' : 'Não',
        'Status Chip': person.status_chip_cliente === '' ? 'Não concluída' : person.status_chip_cliente === null ? 'Não concluída' : person.status_chip_cliente,
        'Data Venda': person.data_venda,
        'Data Cadastro': person.data_cadastro === null || person.data_cadastro === 'undefined' || person.data_cadastro === false ? 'Sem data' : person.data_cadastro,
    }));


    useEffect(() => {
        let filteredResults = people;

        if (lojaVendedorFilter !== '') {
            filteredResults = filteredResults.filter((person) =>
                person.loja_vendedor.includes(lojaVendedorFilter)
            );
        }
        if (cpfFilter !== '') {
            filteredResults = filteredResults.filter((person) =>
                person.cpf_vendedor.includes(cpfFilter)
            );
        }
        if (iccidFilter !== '') {
            filteredResults = filteredResults.filter((person) =>
                person.iccid_venda.includes(iccidFilter)
            );
        }
        if (dataInicialFilter !== '' && dataFinalFilter !== '') {
            console.log(dataInicialFilter && dataFinalFilter);
            const token = sessionStorage.getItem('token');
            axios.get(`https://backend.penambucanas.surf.com.br/relatorio/${dataInicialFilter}/${dataFinalFilter}`,
                { headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    setFilteredData(response.data);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (dataInicialFilter !== '') {
            const dataInicial = new Date(dataInicialFilter + "T00:00:00");

            filteredResults = filteredResults.filter((person) => {
                const dataVenda = new Date(person.data_venda);

                return dataVenda >= dataInicial;
            });
        } else if (dataFinalFilter !== '') {
            const dataFinal = new Date(dataFinalFilter + "T23:59:59");

            filteredResults = filteredResults.filter((person) => {
                const dataVenda = new Date(person.data_venda);

                return dataVenda <= dataFinal;
            });
        }

        setFilteredData(filteredResults);
    }, [lojaVendedorFilter, cpfFilter, iccidFilter, dataInicialFilter, dataFinalFilter, people]);

    let dataToShow = filteredData === '' ? people : filteredData;

    const totalPages = Math.ceil(dataToShow.length / resultsPerPage);
    const indexOfLastResult = currentPage * resultsPerPage;
    const indexOfFirstResult = indexOfLastResult - resultsPerPage;
    const currentResults = dataToShow.slice(indexOfFirstResult, indexOfLastResult);
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg w-full bg-white px-4 pt-2 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-max sm:p-6">
                                <>
                                    <div className="px-4 sm:px-6 lg:px-8 w-full">
                                        <div className="sm:flex sm:items-center">
                                            <div className="sm:flex-auto">
                                                <h1 className="text-2xl font-semibold leading-6 text-gray-900">Relatório</h1>
                                            </div>
                                            <div className={`flex items-center justify-center mt-12 sm:mt-0 sm:ml-4 sm:mb-4`}>
                                                <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                                                    <button
                                                        type="button"
                                                        className="block rounded-md bg-[#1d3c85] py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#1d48ad] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1d3c85]"
                                                    >
                                                        <CSVLink data={csvData} filename={"relatorio.csv"}>
                                                            Exportar Relatório
                                                        </CSVLink>

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-4 sm:grid-cols-2 sm:gap-2 sm:mt-5">
                                            <div className="col-span-1 mr-4 sm:mr-0">
                                                <label htmlFor="account-number"
                                                    className="block text-sm font-medium leading-6 text-gray-900">
                                                    Loja Vendedor
                                                </label>
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                    <input
                                                        type="text"
                                                        name="account-number"
                                                        id="account-number"
                                                        onChange={(e) => setLojaVendedorFilter(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                        placeholder="123456789010"
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-span-1'}>
                                                <label htmlFor="account-number"
                                                    className="block text-sm font-medium leading-6 text-gray-900">
                                                    CPF
                                                </label>
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                    <input
                                                        type="text"
                                                        name="account-number"
                                                        id="account-number"
                                                        onChange={(e) => setCpfFilter(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                        placeholder="000-000-000.00"
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-span-1 ml-4 sm:ml-0'}>
                                                <label htmlFor="account-number"
                                                    className="block text-sm font-medium leading-6 text-gray-900">
                                                    ICCID
                                                </label>
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                    <input
                                                        type="text"
                                                        name="account-number"
                                                        id="account-number"
                                                        onChange={(e) => setIccidFilter(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                        placeholder="123456789010"
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-span-1 ml-4 sm:ml-0'}>
                                                <label htmlFor="data-inicial" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Data inicial
                                                </label>
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                    <input
                                                        type="date"
                                                        id="data-inicial"
                                                        onChange={(e) => setDataInicialFilter(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-span-1 ml-4 sm:ml-0'}>
                                                <label htmlFor="data-final" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Data Fim
                                                </label>
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                    <input
                                                        type="date"
                                                        id="data-final"
                                                        onChange={(e) => setDataFinalFilter(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-8 flow-root">
                                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        <thead>
                                                            <tr>
                                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                                    Loja Vendedor
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Nome Vendedor
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    CPF Vendedor
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Msisdn Vendedor
                                                                </th>

                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Loja Cidade
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Loja UF
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    ICCID Venda
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Plano
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Portabilidade
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Status Portabilidade
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Status Chip
                                                                </th>
                                                                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                    Data Venda
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200">
                                                            {
                                                                isLoading ? (
                                                                    Array.from({ length: 10 }).map((_, index) => (
                                                                        <tr key={index}>
                                                                            <td className="py-4 pr-8 sm:pl-6 lg:pl-8">
                                                                                <div role="status" className="max-w-xs animate-pulse">
                                                                                    <div className="w-12 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                                                                <div className="w-48 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                                                                <div className="w-28 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="p-4">
                                                                                <div className="w-12 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>

                                                                            <td className="p-4">
                                                                                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="p-4">
                                                                                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="p-4">
                                                                                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="p-4">
                                                                                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="p-4">
                                                                                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="p-4">
                                                                                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="p-4">
                                                                                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                            <td className="p-4">
                                                                                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    currentResults.map((person) => (
                                                                        <tr key={person.id}>
                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                                                {person.loja_vendedor}
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {
                                                                                    person.nome_colaborador.length > 20 ? person.nome_colaborador.substring(0, 20) + '...' : person.nome_colaborador
                                                                                }
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.cpf_vendedor}
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {
                                                                                    person.msisdn_vendedor === null ? '' : person.msisdn_vendedor
                                                                                }
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.loja_cidade.length > 9 ? person.loja_cidade.substring(0, 9) + '...' : person.loja_cidade}

                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.loja_uf}
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.iccid_venda}
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.plano.length > 7 ? person.plano.substring(0, 7) + '...' : person.plano}
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.portabilidade === null ||
                                                                                    person.portabilidade === '' ||
                                                                                    person.portabilidade === 'false'
                                                                                    ? 'Não '
                                                                                    : person.portabilidade === 'true'
                                                                                        ? 'Sim'
                                                                                        : 'Não'}
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.status_chip_cliente === ''
                                                                                    ? 'Não concluída'
                                                                                    : person.status_chip_cliente === 'false'
                                                                                        ? 'Sem Portabilidade'
                                                                                        : person.portabilidade === 'true'
                                                                                            ? 'Sim'
                                                                                            : <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-800 ring-1 ring-inset ring-blue-600/20">
                                                                                                Não se aplica
                                                                                            </span>
                                                                                }
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.status_chip_cliente === '' || person.status_chip_cliente === null ? (
                                                                                    <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${person.status_chip_cliente === '' ? 'text-red-500' : 'bg-red-50 text-red-700 ring-red-600/10'}`}>
                                                                                        Não concluído
                                                                                    </span>
                                                                                ) : person.status_chip_cliente === 'PROVISIONED' ? (
                                                                                    <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">
                                                                                        {person.status_chip_cliente}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                                                        {
                                                                                            person.status_chip_cliente === 'ACTIVE' ? 'Chip Ativado' : person.status_chip_cliente
                                                                                        }
                                                                                    </span>
                                                                                )}

                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                                                {person.data_venda}
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <nav
                                        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            <p className="text-sm text-gray-700">
                                                Mostrando{' '}
                                                <span className="font-medium">{indexOfFirstResult + 1}</span> até{' '}
                                                <span className="font-medium">
                                                    {indexOfLastResult > people.length ? people.length : indexOfLastResult}
                                                </span>{' '}
                                                de <span className="font-medium">{people.length}</span> Resultados
                                            </p>
                                        </div>
                                        <div className="flex flex-1 justify-between sm:justify-end">
                                            <button
                                                className={`relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold ${currentPage === 1 ? 'text-gray-300' : 'text-gray-900'
                                                    } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0`}
                                                onClick={() => paginate(currentPage - 1)}
                                                disabled={currentPage === 1}
                                            >
                                                Anterior
                                            </button>
                                            <button
                                                className={`relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold ${indexOfLastResult >= people.length ? 'text-gray-300' : 'text-gray-900'
                                                    } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0`}
                                                onClick={() => paginate(currentPage + 1)}
                                                disabled={indexOfLastResult >= people.length}
                                            >
                                                Próximo
                                            </button>
                                        </div>
                                    </nav>
                                </>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>)
}