import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';

function SalesChart({ data }) {
    const [options, setOptions] = useState({
        chart: {
            height: 240,
            width: 240,
            type: "area",
            fontFamily: "Inter, sans-serif",
            dropShadow: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                opacityFrom: 0.55,
                opacityTo: 0,
                shade: "#1C64F2",
                gradientToColors: ["#1C64F2"],
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 6,
        },
        grid: {
            show: false,
            strokeDashArray: 4,
            padding: {
                left: 2,
                right: 2,
                top: -26,
            },
        },
        series: [
            {
                name: "Total de vendas",
                data: data.developerData,
                color: "#1A56DB",
            },
            {
                name: "Designer Edition",
                data: data.designerData,
                color: "#7E3BF2",
            },
        ],
        xaxis: {
            categories: [
                "01 February",
                "02 February",
                "03 February",
                "04 February",
                "05 February",
                "06 February",
                "07 February",
            ],
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: false,
            labels: {
                formatter: function (value) {
                    return "$" + value;
                },
            },
        },
    });

    useEffect(() => {
        if (document.getElementById('size-chart') && typeof ApexCharts !== 'undefined') {
            const chart = new ApexCharts(document.getElementById('size-chart'), options);
            chart.render();
        }
    }, [data, options]);

    return (
        <div id="size-chart">
            <ApexCharts options={options} series={options.series} type="area" height={120} />
        </div>
    );
}

export default SalesChart;
