import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import { CheckCircleIcon } from '@heroicons/react/20/solid'


function UpdateUser() {
  const [open, setOpen] = useState(true);
  const msisdn = sessionStorage.getItem('msisdn');
  const newMissdn = msisdn.replace(sessionStorage.setItem('msisdn', msisdn));
  const cpf_vendedor = sessionStorage.getItem('cpf');
  const [msisdne, setMsisdn] = useState('');
  const [sucess, setSucess] = useState(false);
  const cancelButtonRef = useRef(null);
  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      msisdn: msisdne,
      cpf_vendedor: cpf_vendedor
    }
    console.log(data)
    const response = await axios.post('https://backend.penambucanas.surf.com.br/updateUser', data);
    try {
      if (response.status === 200) {
        setSucess(true);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto mb-96">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                onSubmit={handleUpdate}

              >
                {
                  sucess !== false ? (
                    <div className="rounded-md bg-green-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-green-800">Numero atualizado com sucesso</h3>
                          <div className="mt-2 text-sm text-green-700">
                            <p>Seu numero estará disponivel no painel após você entrar novamente.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null

                }
                <div className="sm:flex sm:items-start">

                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Alterar Número
                    </Dialog.Title>

                    <label htmlFor="email" className="block text-sm font-medium mt-2 leading-6 text-gray-900">
                      Número
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="newMissdn"
                        id="number"
                        defaultValue={msisdn}
                        onChange={(e) => setMsisdn(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={handleUpdate}
                  >
                    Alterar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root >
  )
}


function UpdateLoja() {
  const [open, setOpen] = useState(true);
  const [newloja, setIloja] = useState('');
  const msisdn = sessionStorage.getItem('msisdn');
  const loja = sessionStorage.getItem('loja');
  const nome = sessionStorage.getItem('nome');
  const cpf = sessionStorage.getItem('cpf');
  const matricula = sessionStorage.getItem('matricula');

  const id = sessionStorage.getItem('id');
  const [sucess, setSucess] = useState(false);
  const cancelButtonRef = useRef(null);
  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      id: id,
      nome: nome,
      newloja: newloja,
      loja: loja,
      cpf: cpf,
      matricula: matricula,
      msisdn: msisdn,
    }
    console.log(data)
    const response = await axios.post('https://backend.penambucanas.surf.com.br/alternateloja', data);
    try {
      if (response.status === 200) {
        setSucess(true);

      }
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto mb-96">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                onSubmit={handleUpdate}
              >
                {
                  sucess !== false ? (
                    <div className="rounded-md bg-yellow-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-yellow-800">Loja atualizado com sucesso</h3>
                          <div className="mt-2 text-sm text-yellow-700">
                            <p>O novo numero de loja estara disponivel após aprovação de um administrador.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null

                }
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Alterar Loja
                    </Dialog.Title>

                    <label htmlFor="email" className="block text-sm font-medium mt-2 leading-6 text-gray-900">
                      Número da loja
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="newMissdn"
                        id="number"
                        defaultValue={loja}
                        onChange={(e) => setIloja(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={handleUpdate}
                  >
                    Alterar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root >
  )
}

export {
  UpdateLoja,
  UpdateUser
}