import Content from "../components/content/content";
import { useState, useEffect, Fragment } from "react";
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    XCircleIcon
} from '@heroicons/react/20/solid'
import axios from "axios";

export default function Historic() {
    const [map, setMap] = useState([]);
    const cpf = sessionStorage.getItem('cpf');
    const loja = sessionStorage.getItem('loja');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(`https://backend.penambucanas.surf.com.br/historico/${cpf}`)
            console.log(result.data)
            setMap(result.data.queryResult)
        }
        fetchData()
    }, [cpf]);

    useEffect(() => {
        if (map.length >= 0) {
            setTimeout(() => {
                setIsLoading(false)
            }, 1000)
        }
    }, [map]);

    const user = sessionStorage.getItem('nome'.split(' ')[0]);
    const number = sessionStorage.getItem('msisdn');

    return (
        <Content title={
            <>
                <h1 className="text-2xl font-semibold leading-6 text-gray-900">Olá, {user}</h1>
                <div className="flex flex-row">
                    <h1 className="text-1xl font-bold mt-1">Seu numero: </h1>
                    <h1 className="text-1xl font-medium mt-1 ml-2">{number}</h1>
                </div>
                <div className="flex flex-row">
                    <h1 className="text-sm font-bold mt-1">Sua loja: </h1>
                    <h1 className="text-sm font-medium mt-1 ml-2">{loja}</h1>
                </div>
            </>
        }
            children={
                <>
                    <div className={`mb-1`}>
                        <h3 className="text-xl leading-6 font-medium text-gray-900">
                            Histórico de vendas
                        </h3>
                    </div>
                    <div className="flex flex-row">
                        <div className={`mb-3 flex items-center justify-between`}>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
                                <div className={`bg-white p-4`}>
                                    <h2 className="text-sm font-semibold flex">Flags de status</h2>
                                    <div className="flex flex-row mt-1">
                                        <div className="flex flex-row mt-1 justify-between">
                                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" title="Ativo" />
                                            <p className="text-sm font-semibold ml-1">Ativo</p>
                                            <ExclamationTriangleIcon className="h-5 w-5 ml-2 text-yellow-400" aria-hidden="true" title="Ativo" />
                                            <p className="text-sm font-semibold ml-1">Pendente</p>
                                            <XCircleIcon className="h-5 w-5 ml-2 text-red-400" aria-hidden="true" title="Ativo" />
                                            <p className="text-sm font-semibold ml-1">Cancelado</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 ">
                        {
                            isLoading ? (
                                Array.from({ length: 16 }).map((_, index) => (
                                    <div key={index} className="bg-white p-4 rounded-lg shadow">

                                        <div className="animate-pulse flex space-x-4">
                                            <div className="flex-1 space-y-4 py-1">
                                                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                                                <div className="space-y-2">
                                                    <div className="h-4 bg-gray-200 rounded"></div>
                                                    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                // resultado pode retornar 0, null ou undefined um array vazio
                            ) : map.length === 0 ? (
                                <>
                                    <div className="flex items-center justify-center">
                                        <h2 className="text-lg font-bold">Nenhuma venda encontrada</h2>
                                    </div>
                                </>
                            ) : (
                                map.map((item) => {
                                    return (
                                        <div key={item.id} className="bg-white p-4 rounded-lg shadow">
                                            <h2 className="text-lg font-bold flex">
                                                ICCID
                                                {item.status_chip_cliente === "ACTIVE" ? (
                                                    <div className="flex flex-row mt-1 ml-2">
                                                        <CheckCircleIcon className="h-5 w-5 text-green-400"
                                                            aria-hidden="true" title="Ativo" />
                                                    </div>
                                                ) : item.status_chip_cliente === "DESACTIVE" ? (
                                                    <div className="flex flex-row mt-1 ml-2">
                                                        <XCircleIcon className="h-5 w-5 text-red-400"
                                                            aria-hidden="true" title="Cancelado" />
                                                    </div>
                                                ) : item.status_chip_cliente === null ? (
                                                    <div className="flex flex-row mt-1 ml-2">
                                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400"
                                                            aria-hidden="true" title="Pendente" />
                                                    </div>
                                                ) : item.status_chip_cliente === "PROVISIONED" || "GRACE1" ? (
                                                    <div className="flex flex-row mt-1 ml-2">
                                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400"
                                                            aria-hidden="true" title="Pendente" />
                                                    </div>
                                                ) : null
                                                }
                                            </h2>
                                            <p className="mt-2 font-bold">Número: <strong
                                                className={`font-medium`}> {item.iccid_venda}</strong></p>
                                            <p className="mt-2 font-bold">Plano: <strong
                                                className={`font-medium`}>{item.plano}</strong></p>
                                            <p className="mt-2 font-bold">Portabilidade:{' '}
                                                <strong className="font-medium">
                                                    {item.portabilidade === "1" || item.portabilidade === true ? 'Sim' : 'Não'}
                                                </strong>
                                            </p>

                                            <p className="mt-2 font-bold">Data: <strong
                                                className={`font-medium`}>{item.data_venda}</strong>
                                            </p>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                </>
            }
        />
    );
}
