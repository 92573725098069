import { React, Fragment, useEffect, useState } from 'react';
import api from '../api/config.axios';
import Content from "../components/content/content";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Notifications from '../components/notifications/notifications.components';
import { Smartphone, FileText, Repeat, Globe, Fingerprint, TicketPlus, Rss, FileClock, Activity, Check, CheckCheck, Calendar } from 'lucide-react';
import { Spinner, Progress } from "@nextui-org/react";

export default function ConsultAccount() {
    const [number, setNumber] = useState('')
    const [data, setData] = useState({});
    const [show, setOpen] = useState(false);
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handlerConsulta = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const request = await api.get(`/get-conta/${number}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            });
            if (request.status === 200) {
                console.log(request.data)
                setData(request.data.result);
            } if (request.status === 401) {

            }
        } catch (e) {
            if (e.response.status === 404) {
                alert('Conta não encontrada');
            } else if (e.response.status === 403) {
                setOpen(true);
                setError(e.message);
                setTimeout(() => {
                    setOpen(false);
                }, 5000);
            } else if (e.response.status === 500) {
                setOpen(true);
                setError(e.message);
                setTimeout(() => {
                    setOpen(false);
                }, 5000);
            } else if (e.message === 'Network Error' || e.code === 'ECONNREFUSED') {
                setOpen(true);
                setError('Erro ao tentar se conectar ao servidor');
                setTimeout(() => {
                    setOpen(false);
                    sessionStorage.removeItem('token');
                }, 5000);
            } else {
                setError('Erro ao tentar se conectar ao servidor');

            }
        } finally {
            setIsLoading(false);
        }
    };

    return (<Fragment>
        <Content>
            <div className='sm:top-0 z-60'>
                <Notifications
                    data={error}
                    show={show}
                    setShow={setOpen} />
            </div>
            {
                isLoading && (
                    <Progress
                        color="primary"
                        size="sm"
                        isIndeterminate
                        className="fixed top-0 left-0 z-50"
                    />
                )
            }
            <div className={'block'}>
                <h1 className={'text-2xl font-medium'}>
                    Consulta de linha
                </h1>
                <span className={'text-sm'}>Consulte a linha de clientes</span>
                <div className={'flex h-20 mt-2 rounded-md'}>
                    <form className={'flex'} onSubmit={handlerConsulta}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                ICCID
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    placeholder="9915170110243262621"
                                    maxLength={19}
                                    minLength={19}
                                    required
                                    onChange={e => setNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div
                            className="flex items-end justify-center mr-2 px-2 py-3">
                            <button
                                type="submit"
                                className="inline-flex rounded-md bg-[#1d3c85] w-28 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1f46a1] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                disabled={isLoading || number.length < 19}
                            >
                                {isLoading ? (
                                    <>
                                        <div className='flex justify-center items-center ml-8'>
                                            <Spinner
                                                size="sm"
                                                color="warning"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        Consultar
                                        <MagnifyingGlassIcon className="ml-1 h-5 w-auto" aria-hidden="true" />
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
                <div>
                    {Object.keys(data).length === 0 ? (
                        <>
                        </>
                    ) : (
                        <>
                            <dl className="mt-5 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4">
                                <div
                                    key={data.id}
                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                            <Smartphone className="h-6 w-6 text-yellow-300" aria-hidden="true" />

                                        </div>
                                        <p className="ml-16 truncate text-md font-medium text-gray-900">MSISDN</p>
                                        <p className="ml-16 truncate text-xl font-medium text-gray-500">{data.profile.msisdn}</p>
                                    </dt>
                                </div>
                                <div
                                    key={data.id}
                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                            <Fingerprint className="h-6 w-6 text-yellow-300" aria-hidden="true" />

                                        </div>
                                        <p className="ml-16 truncate text-md font-medium text-gray-900">ICCID</p>
                                        <p className="ml-16 truncate text-md font-medium text-gray-500">{data.profile.iccid}</p>
                                    </dt>
                                </div>
                                <div
                                    key={data.id}
                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                            <Globe className="h-6 w-6 text-yellow-300" aria-hidden="true" />

                                        </div>
                                        <p className="ml-16 truncate text-md font-medium text-gray-900">IMSI</p>
                                        <p className="ml-16 truncate text-xl font-medium text-gray-500">{data.profile.imsi}</p>
                                    </dt>
                                </div>
                                <div
                                    key={data.id}
                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                            <TicketPlus className="h-6 w-6 text-yellow-300" aria-hidden="true" />

                                        </div>
                                        <p className="ml-16 truncate text-md font-medium text-gray-900">Data Recarga</p>
                                        <p className="ml-16 truncate text-md font-medium text-gray-500">{data.profile.dtUltimaRecarga}</p>
                                    </dt>
                                </div>
                                <div
                                    key={data.id}
                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                            <CheckCheck className="h-6 w-6 text-green-500" aria-hidden="true" />
                                        </div>
                                        <p className="ml-16 truncate text-md font-medium text-gray-900">Data Ativação</p>
                                        <p className="ml-16 truncate text-md font-medium text-gray-500">{data.profile.dtAtivacao}</p>

                                    </dt>
                                </div>

                                <div
                                    key={data.id}
                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                            <FileText className="h-6 w-6 text-yellow-300" aria-hidden="true" />

                                        </div>
                                        <p className="ml-16 truncate text-md font-medium text-gray-900">Plano</p>
                                        <p className="ml-16 truncate text-sm font-medium text-gray-500">{data.profile.noPlano}</p>
                                    </dt>
                                </div>
                                <div
                                    key={data.id}
                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                            <FileClock className="h-6 w-6 text-yellow-300" aria-hidden="true" />

                                        </div>
                                        <p className="ml-16 truncate text-md font-medium text-gray-900">Status Linha</p>
                                        {
                                            data.profile.status === 'ACTIVE' ? (
                                                <>
                                                    <div className="ml-16 flex items-center gap-x-3">
                                                        <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400 pulsar">
                                                            <div className="h-2 w-2 rounded-full bg-current" />
                                                        </div>
                                                        <p className="truncate text-md font-medium text-green-500">{data.profile.status}</p>
                                                    </div>

                                                </>
                                            ) : (
                                                <div className="ml-16 flex items-center gap-x-3">
                                                    <div className="flex-none rounded-full bg-red-400/10 p-1 text-red-400 pulsar">
                                                        <div className="h-2 w-2 rounded-full bg-current" />
                                                    </div>
                                                    <p className="truncate text-md font-medium text-red-500">{data.profile.status}</p>
                                                </div>)
                                        }
                                    </dt>
                                </div>

                                <div
                                    key={data.id}
                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                            <Repeat className="h-6 w-6 text-yellow-300" aria-hidden="true" />

                                        </div>
                                        <p className="ml-16 truncate text-md font-medium text-gray-900">Portabilidade</p>
                                        {
                                            data.profile.stPortin === null ? (
                                                <p className="ml-16 truncate text-md font-medium text-yellow-500">Não se aplica</p>
                                            ) : (
                                                data.profile.stPortin === 1 ? (
                                                    <p className="ml-16 truncate text-md font-medium text-green-500">Sim</p>
                                                ) : (
                                                    <p className="ml-16 truncate text-md font-medium text-red-500">Não</p>
                                                )
                                            )
                                        }
                                    </dt>
                                </div>
                                {
                                    data.portin === null ? (
                                        <>
                                        </>
                                    ) : data.profile.stPortin === null ? (
                                        <>
                                        </>
                                    ) : (
                                        data.profile.stPortin === 1(
                                            <>
                                                <div
                                                    key={data.id}
                                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                                >
                                                    <dt>
                                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                                            <Calendar className="h-6 w-6 text-yellow-300" aria-hidden="true" />
    
                                                        </div>
                                                        <p className="ml-16 truncate text-md font-medium text-gray-900">Janela Portabilidade</p>
                                                        <p className="ml-16 truncate text-md font-medium text-gray-500">{data.portin.dsJanelaPortabilidade}</p>
                                                    </dt>
                                                </div>
                                                <div
                                                    key={data.id}
                                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                                >
                                                    <dt>
                                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                                            <Activity className="h-6 w-6 text-yellow-300" aria-hidden="true" />
    
                                                        </div>
                                                        <p className="ml-16 truncate text-md font-medium text-gray-900">Ticket Portabilidade</p>
                                                        {
                                                            data.portin.nuTicketId === null ? (
                                                                <p className="ml-16 truncate text-md font-medium text-red-500">Não encontrado</p>
                                                            ) : (
                                                                <p className="ml-16 truncate text-md font-medium text-gray-500">{data.portin.nuTicketId}</p>
                                                            )
                                                        }
                                                    </dt>
                                                </div>
                                                <div
                                                    key={data.id}
                                                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                                                >
                                                    <dt>
                                                        <div className="absolute rounded-md bg-[#1d3c85] p-3">
                                                            {
                                                                data.portin.dsStatusParceiro === null ? (
                                                                    <Check className="h-6 w-6 text-yellow-300" aria-hidden="true" />
                                                                ) : (
                                                                    <CheckCheck className="h-6 w-6 text-green-500" aria-hidden="true" />
                                                                )
                                                            }
                                                        </div>
                                                        <p className="ml-16 truncate text-md font-medium text-gray-900">Status Portabilidade</p>
                                                        {
                                                            data.portin.nuTicketId === null ? (
                                                                <p className="ml-16 truncate text-md font-medium text-red-500">Sem Status</p>
                                                            ) : (
                                                                <p className="ml-16 truncate text-md font-medium text-gray-500">{data.portin.dsStatusParceiro}</p>
                                                            )
                                                        }
                                                    </dt>
                                                </div>
                                            </>
                                        ) 
                                    ) 

                                }
                            </dl>
                            <div>
                                {
                                    data.agendamentos && data.agendamentos.length > 0 && (
                                        <>
                                            <div className="px-4 sm:px-6 lg:px-8">
                                                <div className="mt-8 flow-root">
                                                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                            <table className="min-w-full divide-y divide-gray-300">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                                            Nome Campanha
                                                                        </th>
                                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                            Status Campanha
                                                                        </th>
                                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                            Data Agendamento
                                                                        </th>
                                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                            Data Execução
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="divide-y divide-gray-200">
                                                                    {data.agendamentos && data.agendamentos.map((agendamento, index) => (
                                                                        <tr key={agendamento.dsNomeCampanha}>
                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                                                {agendamento.dsNomeCampanha}
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{
                                                                                agendamento.status.nome === 'AGENDADO' ? (
                                                                                    <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                                                                                        {agendamento.status.nome}
                                                                                    </span>
                                                                                ) : agendamento.status.nome === 'EXECUTADO' ? (
                                                                                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                                                        {agendamento.status.nome}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                                                                        Sem status
                                                                                    </span>
                                                                                )
                                                                            }</td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{agendamento.dtAgendamento}</td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{
                                                                                agendamento.dtExecucao === null ? (
                                                                                    <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                                                                        Não executado ainda
                                                                                    </span>
                                                                                ) : (
                                                                                    agendamento.dtExecucao
                                                                                )
                                                                            }</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) || (
                                        <div className="mt-5">
                                            <p className="text-sm text-gray-500">Não há agendamentos para essa linha...</p>
                                        </div>
                                    )

                                }
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Content>
    </Fragment>)
}