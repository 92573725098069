import * as React from 'react';
import logo from '../components/pernambucanas-logo.webp'
import { useNavigate } from 'react-router-dom';
import AlertLogin from '../components/alert/error';
import { Link } from 'react-router-dom';
import ModalCreateAccount from '../components/modals/modalCreate';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
    const [matricula, setMatricula] = React.useState('');
    const [cpf, setCpf] = React.useState('');
    const [error, setError] = React.useState(false);
    const [peding, setPeding] = React.useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://backend.penambucanas.surf.com.br/login', {
                matricula,
                cpf
            });

            sessionStorage.setItem('nome', response.data.data.nome);
            sessionStorage.setItem('id', response.data.data.id);
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('matricula', response.data.data.matricula);
            sessionStorage.setItem('cpf', response.data.data.cpf);
            sessionStorage.setItem('msisdn', response.data.data.msisdn);
            sessionStorage.setItem('nivel', response.data.data.nivel);
            sessionStorage.setItem('loja', response.data.data.loja);
            sessionStorage.setItem('comsisdn', response.data.data.comsisdn);
            navigate('/dashboard');
        } catch (error) {
            if (error.response.status === 401) {
                setError(true);
            } else {
                const result = error.response.data;
                console.log(result);
                if (result && result.code) {
                    if (result.code === 'USER_NOT_FOUND') {
                        setError('Usuário não encontrado.');
                    } else {
                        setPeding(result.message);
                    }
                } else {
                    setError('Ocorreu um erro ao fazer login.');
                }
            }
        }
    };
    const [captchaValue, setCaptchaValue] = React.useState(null);
    const [isCaptchaValidated, setIsCaptchaValidated] = React.useState(false);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setIsCaptchaValidated(true);
    }

    return (
        <>
            <div
                className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-[#fddd04] dark:bg-slate-400">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={logo}
                        alt="Pernambucanas"
                    />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blue-900 dark:text-white">Acesse
                        o portal</h2>
                </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                    <div className='mb-2'>
                        {
                            error !== false ? <AlertLogin /> : true
                        }
                        {
                            peding !== false ? <ModalCreateAccount /> : true
                        }
                    </div>
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    CPF
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="number"
                                        autoComplete="email"
                                        required
                                        maxLength={11}
                                        onChange={(e) => {
                                            setCpf(e.target.value);
                                        }
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Matricula
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="number"
                                        autoComplete="current-password"
                                        required
                                        maxLength={10}
                                        onChange={(e) => {
                                            setMatricula(e.target.value);
                                        }
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-center">

                                <div className="text-sm">
                                    <a className="font-medium text-blue-600 hover:text-blue-500">
                                        <Link to="/newaccount"> Criar conta </Link>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    disabled={!isCaptchaValidated}
                                >
                                    Acesse Portal
                                </button>
                            </div>
                            <div className='flex w-full justify-center'>
                                <ReCAPTCHA
                                    sitekey='6LdYQSYoAAAAABL9TcnyeBe2aiaVJH6X51cUnPT1'
                                    onChange={handleCaptchaChange}
                                />
                            </div>
                        </form>
                        <div className="mt-6">
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
