import { React, useEffect, useState } from "react";
import Content from "../components/content/content";
import { PencilIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { Link } from "react-router-dom";
import { UpdateUser, UpdateLoja } from "../components/modals/modalUpdate";
import SalesChart from "../components/charts/progress.components";

export default function Dashboard2() {
    const user = sessionStorage.getItem('nome'.split(' ')[0])
    const cpf = sessionStorage.getItem('cpf')
    const number = sessionStorage.getItem('msisdn')
    const loja = sessionStorage.getItem('loja')
    const [modal, setModal] = useState(false)
    const [modalLoja, setModalLoja] = useState(false)
    const [valuerecharge, setValuerecharge] = useState(0)
    const [statusChip, setStatusChip] = useState(0);
    const [bonus, setBonus] = useState('');
    const [chartData, setChartData] = useState({
        developerData: [200, 1418, 1456, 1526, 1356, 1256],
        designerData: [643, 413, 765, 412, 1423, 1731],
    });
    useEffect(() => {
        setTimeout(() => {
            setChartData({
                developerData: [1600, 1518, 1556, 1626, 1456, 1356],
                designerData: [663, 433, 775, 422, 1433, 1740],
            });
        }, 2000);
    }, []);
    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await axios.get(`https://backend.penambucanas.surf.com.br/dashboard/${cpf}`);
                setStatusChip(response.data.resultToSend[0]);
            } catch (error) {
                console.error('Failed to fetch dashboard data:', error);
            }
        };

        fetchDashboardData();
    }, [cpf]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://backend.penambucanas.surf.com.br/values/${cpf}`);
                console.log(response);
                const data = response?.data?.resultToSend?.[0] ?? {};

                const bonusCount = data['COUNT(cpf_vendedor)'] || 0;
                setBonus(bonusCount);

                const valueRecharge = bonusCount < 1 ? 0 : bonusCount / 2;
                setValuerecharge(valueRecharge);
                console.log(valueRecharge);
            } catch (error) {
                console.error('Failed to fetch bonus data:', error);
            }
        };

        fetchData();
    }, [cpf]);
    return (
        <>
            <Content title={<>
                <div className="flex flex-row">
                    <h1 className="text-2xl font-semibold leading-6 text-gray-900">Olá, {user}</h1>

                </div>
                <div className="flex flex-row">
                    <h1 className="text-1xl font-bold mt-1">Seu numero: </h1>
                    <h1 className="text-1xl font-medium mt-1 ml-2">{number}</h1>
                    <button
                        type="button"
                        className="rounded-full ml-2 mt-1 bg-[#1d3c85] p-2 text-white shadow-sm hover:bg-[#2550b6] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        onClick={() => setModal(!modal)}
                    >
                        <PencilIcon className="h-3 w-auto" aria-hidden="true" />
                    </button>
                    {
                        modal ? (
                            <>
                                <UpdateUser />
                            </>
                        ) : null
                    }
                </div>
                <div className="flex flex-row">
                    <h1 className="text-sm font-bold mt-1">Sua loja: </h1>
                    <h1 className="text-sm font-medium mt-1 ml-2">{loja}</h1>
                    <button
                        type="button"
                        className="rounded-full ml-2 bg-[#1d3c85] p-2 text-white shadow-sm hover:bg-[#2550b6] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        onClick={() => setModalLoja(!modalLoja == true)}
                    >
                        <PencilIcon className="h-3 w-auto" aria-hidden="true" />
                    </button>
                    {
                        modalLoja ? (
                            <>
                                <UpdateLoja />
                            </>
                        ) : null
                    }
                </div>
            </>
            }
                children={
                    <>
                        <div>
                            <div className="flex flex-row">
                                <h1 className="text-3xl font-medium">
                                    Progresso de ativação
                                </h1>
                            </div>
                            <div className="flex justify-center">
                                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                                    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                        <div className="roudend-lg">
                                            <SalesChart data={chartData} />
                                        </div>
                                    </div>
                                    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                        <dt className="truncate text-lg font-medium text-gray-500">Total de chips vendidos</dt>
                                        {statusChip?.['Chips Vendidos'] > 0 ? (
                                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{statusChip['Chips Vendidos']} Vendidos</dd>
                                        ) : (
                                            <>
                                                <dt className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">0 Vendidos</dt>
                                                <dt className="truncate text-lg whitespace-pre-line font-medium text-gray-500">Não houve chips ativados até o momento.</dt>
                                            </>
                                        )}
                                    </div>
                                    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                        <dt className="truncate text-lg font-medium text-gray-500">Bonus Pernambucanas</dt>
                                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{valuerecharge} GB</dd>
                                    </div>
                                </dl>
                            </div>
                            <div className="flex mt-10 justify-center">
                                <Link to="/cadastro">
                                    <button
                                        type="button"
                                        className="rounded-md bg-[#1d3c85] py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-[#2550b6] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >

                                        Cadastrar nova venda

                                    </button>
                                </Link>
                            </div>
                        </div>

                    </>
                }
            />
        </>
    )
}