import React, { useState, useEffect } from 'react';
import Content from "../components/content/content";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import axios from "axios";
import { RenderLoja, RenderAlter } from "../pages/rendersModal"
import RenderRelatorio2 from "../pages/relatorio.modal"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function Administrativo() {

    const user = sessionStorage.getItem('nome'.split(' ')[0]);
    const msisdn = sessionStorage.getItem('msisdn');
    const loja = sessionStorage.getItem('loja');
    const [relatorioModal, setRelatorioModal] = useState(false);
    const [usersModal, setUsersModal] = useState(false);
    const [alternarModal, setAlternarModal] = useState(false);
    const [vendasactives, setVendasactives] = useState([]);
    const [vendas, setVendas] = useState([]);
    const [usersCount, setUsersCount] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUsersCount = async () => {
            try {
                const response = await axios.get('https://backend.penambucanas.surf.com.br/countusers');
                setUsersCount(response.data.returns);
            } catch (error) {
                console.error('Failed to fetch users count:', error);
            }
        };

        const fetchUsersCountactive = async () => {
            try {
                const response = await axios.get('https://backend.penambucanas.surf.com.br/countucadactives');
                setVendasactives(response.data.returns);
            } catch (error) {
                console.error('Failed to fetch users count:', error);
            }
        };

        const fetchUsersCountcad = async () => {
            try {
                const response = await axios.get('https://backend.penambucanas.surf.com.br/countucad');
                setVendas(response.data.returns);
            } catch (error) {
                console.error('Failed to fetch users count:', error);
            }
        };

        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([fetchUsersCount(), fetchUsersCountactive(), fetchUsersCountcad()]);
        };

        fetchData();
    }, []);

    const stats = [
        { name: 'Total usuários', stat: usersCount },
        { name: 'Pendente Usuarios', stat: vendas },
        { name: 'Chips Ativados', stat: vendasactives },
    ]

    return (
        <>
            <Content title={<>
                <>
                    <h1 className="text-2xl font-semibold leading-6 text-gray-900">Olá, {user}</h1>
                    <div className="flex flex-row">
                        <h1 className="text-1xl font-bold mt-1">Seu numero: </h1>
                        <h1 className="text-1xl font-medium mt-1 ml-2">{msisdn}</h1>
                    </div>
                    <div className="flex flex-row">
                        <h1 className="text-sm font-bold mt-1">Sua loja: </h1>
                        <h1 className="text-sm font-medium mt-1 ml-2">{loja}</h1>
                    </div>
                </>
            </>}
                children={
                    <>

                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-3xl font-semibold mb-2 leading-6 text-gray-900">
                                        Portal Administrativo
                                    </h1>
                                </div>
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                            Links Rápidos
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            Suporte Plaforma
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                        <div className="mt-4">
                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                                {stats.map((item) => (
                                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                        <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        <div className="mt-4">
                            <div className="grid grid-cols-3 gap-3">
                                <button
                                    type="button"
                                    className="rounded-md bg-[#1d3c85] py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-[#244daa] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                    onClick={() => setRelatorioModal(!relatorioModal ? true : false)}
                                >
                                    Relatórios
                                    {
                                        relatorioModal ? <RenderRelatorio2 /> : null
                                    }
                                </button>
                                <button
                                    type="button"
                                    className="rounded-md bg-[#1d3c85] py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-[#244daa] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                    onClick={() => setUsersModal(!usersModal ? true : false)}

                                >
                                    Alterar Loja
                                    {
                                        usersModal ? <RenderAlter /> : null
                                    }
                                </button>
                                <button
                                    type="button"
                                    className="rounded-md bg-[#1d3c85] py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-[#244daa] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                    onClick={() => setAlternarModal(!alternarModal ? true : false)}

                                >
                                    Liberar Acesso
                                    {
                                        alternarModal ? <RenderLoja /> : null
                                    }
                                </button>
                            </div>

                        </div>
                    </>
                }
            />
        </>
    )
}
