import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export function PrivateRoute({ children, adminOnly = false }) {
    const userToken = sessionStorage.getItem('token');
    const nivelUser = sessionStorage.getItem('nivel');
    const navigate = useNavigate();

    useEffect(() => {
        if (userToken) {
            const decodedToken = jwt_decode(userToken);
            const currentTime = Date.now() / 3600;
            if (decodedToken.exp < currentTime) {
                navigate('/');

            }
            // valida o nivel de acesso do usuario logado
            // 0 = developer acesso total inclusive a cadastros relatorios aprovacao e etc
            // 1 = admin acesso total inclusive a cadastros relatorios aprovacao e etc
            // 2 = user acesso basico a cadastros e consultas
            else if (adminOnly && !(nivelUser === '0' || nivelUser === '1')) {
                navigate('/dashboard');
            }
        } else {
            navigate('/');
        }
    }, [userToken, navigate, adminOnly, nivelUser]);

    return userToken ? children : null;
}
